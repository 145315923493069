import React from "react";
import Item, { IItem } from "./Item";
import "./downloaded/css/InlineStyleSheet3.css";

interface IContainer {
  items: IItem[];
}

const Container: React.FC<IContainer> = (container) => {
  return (
    <div className="items">
      {container.items.map((data) => (
        <Item
          id={data.id}
          title={data.title}
          subtitle={data.subtitle}
          key={data.id}
          image={data.image}
          audio={data.audio}
        ></Item>
      ))}
    </div>
  );
};

export default Container;
