import React, { useMemo } from "react";
import styles2 from "./Item.module.css";

export enum IconType {
  Play = 1,
  Audio,
  Pause,
}

interface IconProps {
  type: IconType;
}

const Icon: React.FC<IconProps> = (icon) => {
  const currentIcon = useMemo(() => {
    switch (icon.type) {
      case IconType.Play:
        return "play";
      case IconType.Audio:
        return "audio";
      case IconType.Pause:
        return "pause";
    }
  }, [icon]);

  return (
    <svg className={styles2.icon} xmlns="<http://www.w3.org/2000/svg>">
      <symbol id="ico-audio" viewBox="0 0 22 22">
        <path d="M0 7.17v7.418h4.797l5.996 6.181V.989L4.797 7.17zm16.188 3.709c0-2.225-1.199-4.08-2.998-4.945v9.89c1.799-.865 2.998-2.719 2.998-4.945zM13.191 0v2.596c3.478 1.113 5.996 4.327 5.996 8.283s-2.518 7.17-5.996 8.283v2.596c4.797-1.113 8.394-5.563 8.394-10.879S17.987 1.113 13.191 0z" />
      </symbol>
      <symbol id="ico-pause" viewBox="0 0 11 14">
        <path d="M0 14h3.667V0H0zM7.333 0v14H11V0z" />
      </symbol>
      <symbol id="ico-play" viewBox="0 0 11 14">
        <path d="M0 0v14l11-7z" />
      </symbol>
      <use href={`#ico-${currentIcon}`} />
    </svg>
  );
};

export default Icon;
