import React from "react";
import styles from "./downloaded/css/InlineStyleSheet2.css";
import styles2 from "./Item.module.css";
import "./downloaded/css/styles.css";
import useAudio from "./components/audio-library/useAudio";
import Icon, { IconType } from "./Icon";

export interface IItem {
  id: number;
  title: string;
  subtitle: string;
  image: string;
  audio: string;
}

const Item: React.FC<IItem> = ({ title, subtitle, image, audio }) => {
  const {
    ready,
    loading,
    error,
    playing,
    paused,
    duration,
    mute,
    loop,
    volume,
    seek,
    rate,
    onToggle,
    onPlay,
    onPause,
    onMute,
    onLoop,
    onVolume,
    onRate,
    onSeek,
  } = useAudio({
    src: audio,
    preload: true,
    autoplay: false,
    volume: 0.5,
    mute: false,
    loop: false,
    rate: 1.0,
  });

  return (
    <div className="audio-item">
      <button className="audio-button" onClick={onToggle}>
        <div className="audio-image">
          <img
            data-src={image}
            alt={`${title} - ${title}`}
            className="lazyloaded"
            src={image}
          />
          <div className={styles2.audioOverlay}>
            <svg width="22" height="22" className={`${styles.icon}`}>
              <use href="https://internetgekki.es/wp-content/themes/internetgekkies/assets/build/svg/sprite.icons.svg?id=bdc1064d77e369e841ae#ico-refresh"></use>
            </svg>

            {!playing && !paused && <Icon type={IconType.Play} />}
            {playing && <Icon type={IconType.Audio} />}
            {paused && <Icon type={IconType.Pause} />}
          </div>
        </div>
      </button>
      <div className="audio-content">
        <h3 className="audio-title">
          <span className="audio-title-main">{title}</span>
          <span className="screen-reader-text">-</span>
          <span className="audio-title-sub">{subtitle}</span>
        </h3>
      </div>
    </div>
  );
};

export default Item;
