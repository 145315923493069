export const audioFiles = [
  {
    id: 1,
    title: "Mevrouw",
    subtitle: "Wil jij je grote rotbek dichthouden, zwartjoekel",
    image:
      "https://internetgekki.es/wp-content/uploads/2022/01/mevrouw-wil-jij-je-grote-rotbek-dichthouden-zwartjoekel.jpg",
    audio:
      "https://internetgekki.es/wp-content/uploads/2022/01/mevrouw-wil-jij-je-grote-rotbek-dichthouden-zwartjoekel.mp3",
    youtube: "https://www.youtube.com/watch?v=3k6dcrvNShE",
    gif: "https://tenor.com/view/smerige-rot-bek-wil-jij-je-grote-dicht-houden-bek-dicht-mond-dicht-gif-12985824",
  },

  {
    id: 2,
    title: "Alwin",
    subtitle: "Godverdomme kutkind",
    image:
      "https://internetgekki.es/wp-content/uploads/2022/01/alwin-godverdomme-kutkind.jpg",
    audio:
      "https://internetgekki.es/wp-content/uploads/2022/01/alwin-godverdomme-kutkind.mp3",
    youtube: "https://www.youtube.com/watch?v=-uD91VGMC_8",
  },
  {
    id: 3,
    title: "Zwevende kiezer",
    subtitle: "CDA is ChristenUnie",
    image:
      "https://internetgekki.es/wp-content/uploads/2022/01/zwevende-kiezer-cda-is-christenunie.jpg",
    audio:
      "https://internetgekki.es/wp-content/uploads/2022/01/zwevende-kiezer-cda-is-christenunie.mp3",
  },
  {
    id: 4,
    title: "Moeder van Angelo",
    subtitle: "Je bent een lomp ventje",
    image:
      "https://internetgekki.es/wp-content/uploads/2022/01/moeder-van-angelo-je-bent-een-lomp-ventje.jpg",
    audio:
      "https://internetgekki.es/wp-content/uploads/2022/01/moeder-van-angelo-je-bent-een-lomp-ventje.mp3",
  },
  {
    id: 5,
    title: "Leon",
    subtitle: "Distantieer mij niet van smeerpijperij",
    image:
      "https://internetgekki.es/wp-content/uploads/2022/01/leon-distantieer-mij-niet-van-smeerpijperij.jpg",
    audio:
      "https://internetgekki.es/wp-content/uploads/2022/01/leon-distantieer-mij-niet-van-smeerpijperij.mp3",
  },
  {
    id: 6,
    title: "Wouter",
    subtitle: "Ik heb echt een zware mannenstem",
    image:
      "https://internetgekki.es/wp-content/uploads/2022/01/wouter-zware-mannenstem.jpg",
    audio:
      "https://internetgekki.es/wp-content/uploads/2022/01/wouter-zware-mannenstem.mp3",
  },
  {
    id: 7,
    title: "Iva",
    subtitle: "Ik heb deze tasje aan",
    image:
      "https://internetgekki.es/wp-content/uploads/2022/01/iva-ik-heb-deze-tasje-aan.jpg",
    audio:
      "https://internetgekki.es/wp-content/uploads/2022/01/iva-ik-heb-deze-tasje-aan.mp3",
  },
  {
    id: 8,
    title: "Bloempot meneer",
    subtitle: "Bloempohoooot!!!",
    image:
      "https://internetgekki.es/wp-content/uploads/2022/01/bloempot-meneer-bloempohoooot.jpg",
    audio:
      "https://internetgekki.es/wp-content/uploads/2022/01/bloempot-meneer-bloempohoooot.mp3",
  },
];
